<template>
  <a href="#home">
    <img src="@/assets/images/up.svg" />
  </a>
</template>

<script>
export default {
  name: 'GoHome'
}
</script>

<style scoped>
a {
  width: 50px;
  height: 50px;

  position: absolute;
  top: 10px;
  right: 10px;
}

img {
  width: 100%;
  height: 100%;

  color: red;
}
</style>
